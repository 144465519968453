import StoreInfo from '@common/models/StoreInfo';
import { CustomPagesSliceType } from '@common/store/customPages/slice';
import { FeaturesSliceType } from '@common/store/features/slice';
import { FilterBarSliceType } from '@common/store/filterBar/slice';
import { PageHeaderSliceType } from '@common/store/pageHeader/slice';
import { PoliciesSliceTosType } from '@common/store/policiesTos/slice';
import { getActiveNavbar } from '@common/utils';
import { isMobile } from '@common/utils';
import Facebook from '@images/FooterFacebook.svg';
import Instagram from '@images/FooterInstagram.svg';
import Linkedin from '@images/FooterLinkedin.svg';
import Pinterest from '@images/FooterPinterest.svg';
import Twitter from '@images/FooterTwitter.svg';
import Youtube from '@images/FooterYoutube.svg';
import Image from '@lib/Image';
import Link from '@lib/Link';
import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../../common/store';

interface FooterProps {
  storeInfo: StoreInfo;
  customPages: CustomPagesSliceType;
  features: FeaturesSliceType;
  filterBar: FilterBarSliceType;
  policies: PoliciesSliceTosType;
  pageHeader: PageHeaderSliceType;
  pageHeaderID: number;
}

const Footer: React.FunctionComponent<FooterProps> = ({
  storeInfo,
  features,
  filterBar,
  policies: { policyTos },
  pageHeader,
  pageHeaderID,
}) => {
  const quickLinks =
    'text-center md:text-left md:w-full w-fit mx-auto md:mx-0 mb-14 md:mb-20 flex text-footerMenu text-16 leading-24 font-normal';
  const header = 'text-center md:text-left text-page capitalize leading-30 text-21 mb-16 font-semibold';
  const arePoliciesPresent = Object.keys(policyTos).length > 0;
  const pageHeaderLayout = pageHeader[pageHeaderID]?.pageHeader;
  return (
    <div className="w-full pt-40 pb-30 bg-neutral" color-customisation="footer-bg" id="page-footer">
      <div className="container mx-auto">
        <div className="block md:hidden py-12 ">
          <div className="flex flex-col">
            <Link to="/" className="flex rounded mx-auto mb-32 overflow-hidden" id="mobile-footer-logo">
              {pageHeaderLayout?.logo ? (
                <Image
                  noDefaultDimensions
                  src={pageHeaderLayout?.logo}
                  alt={storeInfo?.storename}
                  className="overflow-hidden object-contain h-44"
                />
              ) : (
                <div className="md:font-medium text-20 text-white ">{storeInfo?.storename}</div>
              )}
            </Link>
            <div className="py-10">
              <span className="flex mt-0 mb-20 md:mb-0 socialLinks items-center">
                {storeInfo?.hasSocialLinks ? (
                  <div className="flex w-full items-center justify-center flex-row">
                    <div className="md:mb-0 mr-20 md:mr-14">
                      <p
                        className="text-16 text-white md:hidden opacity-50"
                        font-customisation="para-text"
                        color-customisation="footer-heading"
                      >
                        Follow us
                      </p>
                    </div>
                    <div className="flex items-center">
                      {storeInfo?.social?.facebook ? (
                        <a href={storeInfo?.social?.facebook} target="_black()" title="Facebook">
                          {/* <FacebookFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24  h-18" /> */}
                          <Image
                            noDefaultDimensions={true}
                            className="md:w-20 md:h-20 md:mr-24 w-17 mr-24 h-17"
                            id="mobile-footer-facebook-link"
                            src={Facebook}
                          />
                        </a>
                      ) : null}
                      {storeInfo?.social?.twitter ? (
                        <a href={storeInfo?.social?.twitter} target="_black()" title="Twitter">
                          <Image
                            noDefaultDimensions={true}
                            className="md:w-20 md:h-20 md:mr-24 w-17 mr-24 h-17"
                            id="mobile-footer-twitter-link"
                            src={Twitter}
                          />
                        </a>
                      ) : null}
                      {storeInfo?.social?.instagram ? (
                        <a href={storeInfo?.social?.instagram} target="_black()" title="Instagram">
                          <Image
                            noDefaultDimensions={true}
                            className="md:w-20 md:h-20 md:mr-24 w-17 mr-24 h-17"
                            id="mobile-footer-instagram-link"
                            src={Instagram}
                          />
                        </a>
                      ) : null}
                      {storeInfo?.social?.pinterest ? (
                        <a href={storeInfo?.social?.pinterest} target="_black()" title="Pinterest">
                          {/* <PinterestFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-10  h-18" /> */}
                          <Image
                            noDefaultDimensions={true}
                            className="md:w-20 md:h-20 md:mr-24 w-17 mr-24 h-17"
                            id="mobile-footer-pinterest-link"
                            src={Pinterest}
                          />
                        </a>
                      ) : null}
                      {storeInfo?.social?.youtube ? (
                        <a href={storeInfo?.social?.youtube} target="_black()" title="YouTube">
                          <Image
                            noDefaultDimensions={true}
                            className="md:w-20 md:h-20 md:mr-24 w-20 mr-24 h-20 opacity-50"
                            id="mobile-footer-youtube-link"
                            src={Youtube}
                          />
                        </a>
                      ) : null}
                      {storeInfo?.social?.linkedin ? (
                        <a href={storeInfo?.social?.linkedin} target="_black()" title="LinkedIn">
                          <Image
                            noDefaultDimensions={true}
                            className="md:w-24 md:h-16 md:mr-24 w-16 mr-24 h-15 -mt-2"
                            src={Linkedin}
                            id="mobile-footer-linkedin-link"
                          />
                        </a>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {/* <InstamojoLogo /> */}
              </span>
            </div>
          </div>
        </div>
        <div className=" md:justify-between justify-center">
          <div className="md:block hidden mt-10 mb-40">
            <div className="flex flex-row justify-between">
              <Link to="/" id="footer-logo">
                {pageHeaderLayout?.logo ? (
                  <Image
                    noDefaultDimensions={true}
                    src={pageHeaderLayout?.logo}
                    alt={storeInfo?.storename}
                    className=" h-44 rounded"
                  />
                ) : (
                  <div className="font-medium text-30 text-white">{storeInfo?.storename}</div>
                )}
              </Link>
              <div className="py-10">
                <span className="flex mt-0 mb-20 md:mb-0 socialLinks items-center">
                  {storeInfo?.hasSocialLinks ? (
                    <div className="flex w-full items-center justify-center flex-col md:flex-row flex-wrap">
                      <div className="md:mb-0 mr-0 md:mr-14">
                        <p
                          className="text-14 md:text-16 text-white md:hidden"
                          font-customisation="para-text"
                          color-customisation="footer-heading"
                        >
                          Follow us
                        </p>
                      </div>
                      <div className="flex items-center">
                        {storeInfo?.social?.facebook ? (
                          <a href={storeInfo?.social?.facebook} target="_black()" title="Facebook">
                            {/* <FacebookFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24  h-18" /> */}
                            <Image
                              noDefaultDimensions={true}
                              className="md:w-20 md:h-20 md:mr-24 w-17 mr-24 h-17"
                              id="footer-facebook-link"
                              src={Facebook}
                            />
                          </a>
                        ) : null}
                        {storeInfo?.social?.twitter ? (
                          <a href={storeInfo?.social?.twitter} target="_black()" title="Twitter">
                            <Image
                              noDefaultDimensions={true}
                              className="md:w-20 md:h-20 md:mr-24 w-17 mr-24 h-17"
                              id="footer-twitter-link"
                              src={Twitter}
                            />
                          </a>
                        ) : null}
                        {storeInfo?.social?.instagram ? (
                          <a href={storeInfo?.social?.instagram} target="_black()" title="Instagram">
                            <Image
                              noDefaultDimensions={true}
                              className="md:w-20 md:h-20 md:mr-24 w-17 mr-24 h-17"
                              id="footer-instagram-link"
                              src={Instagram}
                            />
                          </a>
                        ) : null}
                        {storeInfo?.social?.pinterest ? (
                          <a href={storeInfo?.social?.pinterest} target="_black()" title="Pinterest">
                            {/* <PinterestFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-10  h-18" /> */}
                            <Image
                              noDefaultDimensions={true}
                              className="md:w-20 md:h-20 md:mr-24 w-17 mr-24 h-17"
                              id="footer-pinterest-link"
                              src={Pinterest}
                            />
                          </a>
                        ) : null}
                        {storeInfo?.social?.youtube ? (
                          <a href={storeInfo?.social?.youtube} target="_black()" title="YouTube">
                            <Image
                              noDefaultDimensions={true}
                              className="md:w-20 md:h-20 md:mr-24 w-20 mr-24 h-20 opacity-50"
                              id="footer-youtube-link"
                              src={Youtube}
                            />
                          </a>
                        ) : null}
                        {storeInfo?.social?.linkedin ? (
                          <a href={storeInfo?.social?.linkedin} target="_black()" title="LinkedIn">
                            <Image
                              noDefaultDimensions={true}
                              className="md:w-24 md:h-16 md:mr-24 w-16 mr-24 h-15 -mt-2"
                              id="footer-linkedin-link"
                              src={Linkedin}
                            />
                          </a>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                  {/* <InstamojoLogo /> */}
                </span>
              </div>
            </div>
          </div>
          <div className="mb-30 border border-pageFooter border-opcaity-10"></div>
          <div className="grid grid-cols-1 sm:grid-cols-4 gap-1 md:px-4">
            <div className="mb-50 md:mb-0">
              <div className={header} font-customisation="section-heading" color-customisation="footer-heading">
                Menu
              </div>
              {arePoliciesPresent && !policyTos?.privacy && (
                <Link
                  to="/policy/privacy"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-privacy"
                >
                  Privacy
                </Link>
              )}
              {arePoliciesPresent && !policyTos?.termsAndConditions && (
                <Link
                  to="/policy/tnc"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-terms-conditions"
                >
                  Terms & Conditions
                </Link>
              )}
              {features.features?.contactUsPage && getActiveNavbar('Contact Us', filterBar) ? (
                <Link
                  to="/contactus"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-contact-us"
                >
                  Contact Us
                </Link>
              ) : null}
              {getActiveNavbar('About Us', filterBar) && (
                <Link
                  to="/aboutus"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-about-us"
                >
                  About Us
                </Link>
              )}
              {getActiveNavbar('FAQs', filterBar) && (
                <Link
                  to="/faqs"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-faqs"
                >
                  FAQs
                </Link>
              )}
            </div>
            <div className="mb-50 md:mb-0">
              <div className={header} font-customisation="section-heading" color-customisation="footer-heading">
                Account
              </div>
              <Link
                to="/track-order"
                className={quickLinks}
                font-customisation="para-text"
                color-customisation="footer-links"
                id="footer-track-order"
              >
                Track Order
              </Link>
              {arePoliciesPresent && !policyTos?.cancelAndRefund && (
                <Link
                  to="/policy/cancellation"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-cancellation-refund"
                >
                  Cancellation &amp; Refund
                </Link>
              )}
              {arePoliciesPresent && !policyTos?.shippingAndDelivery && (
                <Link
                  id="footer-shipping-delivery"
                  to="/policy/shipping"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                >
                  Shipping &amp; Delivery
                </Link>
              )}
              {/* {customPages?.pages?.results?.map((page) => {
                if ((getBuyerJWTToken() && page.protected) || !page.protected) {
                  return (
                    <Link key={page.id} to={page.url} className={quickLinks}>
                      {page.name}
                    </Link>
                  );
                } else {
                  return null;
                }
              })} */}
            </div>
            <div className="mb-50 md:mb-0">
              <div className={header} font-customisation="section-heading" color-customisation="footer-heading">
                Company
              </div>

              <div className=" text-priceText ">
                {storeInfo?.contactInfo?.address ? (
                  <p
                    className={quickLinks}
                    font-customisation="para-text"
                    color-customisation="footer-links"
                    id="footer-contact-address"
                  >
                    {/* <Image src={Pin} alt="Email" className="mr-14" /> */}
                    {storeInfo?.contactInfo?.address}
                  </p>
                ) : null}
                {storeInfo?.contactInfo?.number ? (
                  <p className={quickLinks} font-customisation="para-text" color-customisation="footer-links">
                    {/* <Image src={Phone} alt="Phone" className="mr-14" style={{ filter: 'brightness(0.3)' }} /> */}
                    <a href={`tel:${storeInfo?.contactInfo?.number}`} id="footer-contact-number">
                      {storeInfo?.contactInfo?.number}
                    </a>
                  </p>
                ) : null}
                {storeInfo?.contactInfo?.email ? (
                  <p className={quickLinks} font-customisation="para-text" color-customisation="footer-links">
                    {/* <Image src={Email} alt="Email" className="mr-14" style={{ filter: 'brightness(0.3)' }} /> */}
                    <a
                      href={`${isMobile() ? 'mailto:' : 'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to='}${
                        storeInfo?.contactInfo?.email
                      }`}
                      className="underline"
                    >
                      <p className="" id="footer-contact-mail">
                        {storeInfo?.contactInfo?.email}
                      </p>
                    </a>
                  </p>
                ) : null}
              </div>
            </div>
            {/* {storeInfo?.hasSocialLinks ? (
              <div className="mb-30  md:mb-0">
                <div className={header}>Stay Connected</div>
                <div className=" items-center">
                  {storeInfo?.social?.facebook ? (
                    <a className={quickLinks} href={storeInfo?.social?.facebook} target="_black()">
                      Facebook
                    </a>
                  ) : null}
                  {storeInfo?.social?.twitter ? (
                    <a className={quickLinks} href={storeInfo?.social?.twitter} target="_black()">
                      Twitter
                    </a>
                  ) : null}
                  {storeInfo?.social?.instagram ? (
                    <a className={quickLinks} href={storeInfo?.social?.instagram} target="_black()">
                      Instagram
                    </a>
                  ) : null}
                  {storeInfo?.social?.pinterest ? (
                    <a className={quickLinks} href={storeInfo?.social?.pinterest} target="_black()">
                      Pinterest
                    </a>
                  ) : null}
                  {storeInfo?.social?.youtube ? (
                    <a className={quickLinks} href={storeInfo?.social?.youtube} target="_black()">
                      Youtube
                    </a>
                  ) : null}
                  {storeInfo?.social?.linkedin ? (
                    <a className={quickLinks} href={storeInfo?.social?.linkedin} target="_black()">
                      Linkedin
                    </a>
                  ) : null}
                </div>
              </div>
            ) : storeInfo?.hasSocialLinks === false && getMerchantJWTToken() ? (
              <a className="h-68" href={getAddFooterUrl()}>
                <div className="cursor-pointer flex items-center text-white rounded-sm border border-white border-opacity-50 p-16">
                  <div className="mr-16 font-bold text-16">+</div>
                  <div>
                    <div className="text-16 font-medium">Add Your Store Links</div>
                    <div className="text-10">Facebook, Instagram, WhatsApp, Twitter etc.</div>
                  </div>
                </div>
              </a>
            ) : null} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(({ pageHeader }: RootState) => ({ pageHeader }))(Footer);
